import $ from "jquery";

function change_visibility(status) {
  if (status === "Scheduled") {
    $(".published-field").show();
  } else {
    $(".published-field").hide();
  }
}

$(document).ready(function () {
  change_visibility($("#article_status :selected").text());

  $("#article_status").on("change", function (e) {
    change_visibility($(this).find(":selected").text());
  });

  $("#article_form_submit_button").on("click", function (e) {
    $("#validation-errors-container").html("");
    $(".js-loader").removeClass("hidden");
  });
});
